import primary from "@material-ui/core/colors/deepOrange";
import secondary from "@material-ui/core/colors/grey";

export function getTheme(type) {
  return {
    palette: {
      type,
      primary: primary,
      secondary: secondary,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 360,
        md: 620,
        lg: 960,
        xl: 1200,
      },
    },
    typography: {
      h6: {
        fontSize: "1.05rem",
      },
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          "&:hover": {
            color: "#fff",
          },
        },
      },
      MuiIconButton: {
        root: {
          "&:hover": {
            color: "inherit",
          },
        },
      },
      MuiCardActionArea: {
        root: {
          "&:hover": {
            color: "inherit",
          },
        },
      },
    },
  };
}
