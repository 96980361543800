import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  project: {
    width: "100%",
    height: "100%",
    minHeight: 200,
    backgroundColor: theme.palette.secondary[200],
    color: "black",
    textAlign: "left",
    "& h2": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "& p": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxHeight: 60,
    },
  },
  image: {
    height: 120,
    backgroundColor: "white",
  },
  action: {
    height: "100%",
  },
  actionNone: {
    cursor: "default",
  },
}));

function Project(props) {
  const classes = useStyles(props);
  let actionClasses = classes.action;
  if (!props.href) actionClasses += " " + classes.actionNone;
  return (
    <Card className={classes.project}>
      <CardActionArea
        className={actionClasses}
        component={props.href ? "a" : "div"}
        href={props.href}
        target="_blank"
      >
        <CardMedia
          className={classes.image}
          image={props.image}
          title={props.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" component="p">
            {props.body}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default Project;
