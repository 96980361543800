import React from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";

import { ReactComponent as LinkedInLogo } from "../assets/linkedin.svg";
import { getTheme } from "../theme";

const headerTheme = createMuiTheme({
  ...getTheme('light'),
  breakpoints: {
    ...getTheme('light').breakpoints,
    values: {
      xs: 0,
      sm: 726,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% + 16px)",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },
  img: {
    /*backgroundColor: "green",*/
  },
  txt: {
    /*backgroundColor: "yellow",*/
  },
  subGrid: {
    width: "100%",
    height: "100%",
    "& .buttons": {
      paddingTop: 16,
    },
  },
  avatar: {
    width: 180,
    height: 180,
    border: "2px solid white",
    borderRadius: "200px",
    [theme.breakpoints.up("sm")]: {
      margin: "auto",
    },
  },
  title: {
    fontFamily: "Raleway",
    fontWeight: 500,
    fontSize: 52,
    lineHeight: "60px",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: 42,
    },
  },
  subTitle: {
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "22px",
    color: "white",
    paddingRight: 10,
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  linkedin: {
    padding: 8,
    borderRadius: 16,
    backgroundColor: "white",
    "& svg": {
      width: 16,
      height: 16,
      margin: "-0.5px -0.5px 0.5px 0.5px",
      transition: theme.transitions.create(["background-color", "fill"], {
        duration: 150,
      }),
      fill: "black",
    },
    "&:hover": {
      backgroundColor: "#2867b2",
      "& svg": {
        fill: "white",
      },
    },
  },
}));

function HeaderContent(props) {
  const classes = useStyles(props);
  return (
    <Grid
      container
      spacing={2}
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={4} className={classes.img}>
        <Avatar
          alt="Eric"
          src={require("../assets/DSC_6808_closer_rounded_512.jpg")}
          className={classes.avatar}
        />
      </Grid>
      <Grid item xs={12} sm={8} className={classes.txt}>
        <Grid
          container
          spacing={1}
          className={classes.subGrid}
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <span className={classes.title}>Eric Seckler</span>
          </Grid>
          <Grid item xs={12}>
            <span className={classes.subTitle}>Software engineer.</span>
            <span className={classes.subTitle}>Photographer.</span>
            <span className={classes.subTitle}>Husband.</span>
          </Grid>
          <Grid item xs={12} className="buttons">
            <Grid
              container
              spacing={1}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<MailOutlineRoundedIcon />}
                  href="&#109;&#x61;&#x69;&#108;&#x74;&#x6f;&#58;&#x65;&#x72;&#105;&#x63;&#x40;&#115;&#x65;&#x63;&#107;&#x6c;&#x65;&#114;&#x2e;&#x6f;&#114;&#x67;"
                  target="_blank"
                >
                  Contact
                </Button>
              </Grid>
              <Grid item xs="auto">
                <IconButton
                  aria-label="LinkedIn"
                  className={classes.linkedin}
                  href="https://www.linkedin.com/in/ericseckler"
                  target="_blank"
                >
                  <LinkedInLogo />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Header(props) {
  return (
    <ThemeProvider theme={headerTheme}>
      <HeaderContent />
    </ThemeProvider>
  );
}

export default Header;
