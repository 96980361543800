import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import PhotoSwipe from "photoswipe";
import PhotoswipeUIDefault from "photoswipe/dist/photoswipe-ui-default";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import PhotoGroup from "./PhotoGroup.js";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 8px 8px 8px",
    flexBasis: "auto",
  },
}));

function Photos(props) {
  const classes = useStyles(props);

  const open = useCallback(
    (groupNumber, picNumber) => {
      const layoutEl = props.photoSwipeLayout.current;
      const group = props.groups[groupNumber - 1];

      if (!layoutEl) return;

      const instance = new PhotoSwipe(
        layoutEl,
        PhotoswipeUIDefault,
        group.items,
        {
          index: picNumber - 1,
          galleryUID: groupNumber,
          history: true,
          bgOpacity: 0.9,
          showHideOpacity: true,
          loop: false,
          captionEl: false,
          fullscreenEl: false,
          zoomEl: false,
          shareEl: false,
          counterEl: false,
          clickToCloseNonZoomable: false,
          timeToIdle: 0,
          timeToIdleOutside: 0,
          barsSize: { top: 0, bottom: 0 },
        }
      );

      let minimap = layoutEl.getElementsByClassName("pswp__minimap")[0];

      // Remove all children of minimap.
      const clonedMinimap = minimap.cloneNode(false);
      minimap.parentNode.replaceChild(clonedMinimap, minimap);
      minimap = clonedMinimap;

      let imageSize = "lg";
      let firstResize = false;

      group.items.forEach((_, index) => {
        const counterItem = document.createElement("div");
        if (index === picNumber - 1) counterItem.className = "active";
        minimap.appendChild(counterItem);
      });

      instance.listen("beforeChange", () => {
        const activeIndex = instance.getCurrentIndex();
        minimap.childNodes.forEach((counterItem, index) => {
          if (index === activeIndex) counterItem.className = "active";
          else counterItem.className = undefined;
        });
      });

      instance.listen("beforeResize", () => {
        const realViewportWidth =
          instance.viewportSize.x * window.devicePixelRatio;
        const realViewportHeight =
          instance.viewportSize.y * window.devicePixelRatio;
        const minDimensionPx = Math.floor(
          Math.min(realViewportWidth, realViewportHeight)
        );

        const newImageSize = minDimensionPx > 1080 ? "xl" : "lg";
        if (newImageSize !== imageSize) {
          imageSize = newImageSize;
          // No need to invalidate items if this is the first resize.
          if (!firstResize) instance.invalidateCurrItems();
        }
      });

      instance.listen("gettingData", (index, item) => {
        if ("lg" in item && "xl" in item) {
          Object.assign(item, item[imageSize]);
        }
      });

      instance.init();
    },
    [props.groups, props.photoSwipeLayout]
  );

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const params = {};

    if (hash.length < 5) return;

    const vars = hash.split("&");

    for (let i = 0; i < vars.length; i++) {
      if (vars[i]) {
        const [key, value] = vars[i].split("=");
        if (key && value) {
          params[key] = value;
        }
      }
    }

    const { gid, pid } = params;

    if (gid && pid) open(gid, pid);
  }, [open]);

  return (
    <Grid
      container
      spacing={2}
      className={classes.root}
      justify="center"
      alignItems="flex-start"
    >
      {props.groups.map((group, groupIndex) => (
        <Grid
          item
          key={group.title}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className={classes.item}
        >
          <PhotoGroup group={group} groupNumber={groupIndex + 1} open={open} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Photos;
