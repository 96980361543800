import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".pswp__minimap": {
      width: "100%",
      position: "absolute",
      left: 0,
      bottom: 16,
      textAlign: "center",
      "&>div": {
        display: "inline-block",
        width: 8,
        height: 8,
        borderRadius: 4,
        border: "1px solid white",
        margin: 4,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        filter: "drop-shadow( 0px 0px 2px rgba(0, 0, 0, .7))",
        transition: theme.transitions.create(["background-color"], {
          duration: 150,
        }),
      },
      "&>div.active": {
        backgroundColor: "#fff",
      },
    },
    ".pswp__top-bar": {
      backgroundColor: "transparent",
    },
    ".pswp__button:before": {
      backgroundColor: "transparent",
      filter: "drop-shadow( 0px 0px 2px rgba(0, 0, 0, .7))",
    },
  },
}));

const PhotoSwipeLayout = React.forwardRef((props, ref) => {
  useStyles(props);
  return (
    /* Root element of PhotoSwipe. Must have class pswp. */
    <div
      className="pswp"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      ref={ref}
    >
      {/* Background of PhotoSwipe. 
          It's a separate element as animating opacity is faster than rgba().
          */}
      <div className="pswp__bg"></div>

      {/* Slides wrapper with overflow:hidden. */}
      <div className="pswp__scroll-wrap">
        {/* Container that holds slides. 
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
            Don't modify these 3 pswp__item elements, data is added later on.
            */}
        <div className="pswp__container">
          <div className="pswp__item"></div>
          <div className="pswp__item"></div>
          <div className="pswp__item"></div>
        </div>

        {/* Default (PhotoSwipeUI_Default) interface on top of sliding area.
            Can be changed. */}
        <div className="pswp__ui pswp__ui--hidden">
          <div className="pswp__top-bar">
            {/*  Controls are self-explanatory. Order can be changed. */}

            <div className="pswp__counter"></div>

            <button
              className="pswp__button pswp__button--close"
              title="Close (Esc)"
            ></button>

            <button
              className="pswp__button pswp__button--share"
              title="Share"
            ></button>

            <button
              className="pswp__button pswp__button--fs"
              title="Toggle fullscreen"
            ></button>

            <button
              className="pswp__button pswp__button--zoom"
              title="Zoom in/out"
            ></button>

            {/* Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR
                element will get class pswp__preloader--active when preloader is
                running */}
            <div className="pswp__preloader">
              <div className="pswp__preloader__icn">
                <div className="pswp__preloader__cut">
                  <div className="pswp__preloader__donut"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div className="pswp__share-tooltip"></div>
          </div>

          <button
            className="pswp__button pswp__button--arrow--left"
            title="Previous (arrow left)"
          ></button>

          <button
            className="pswp__button pswp__button--arrow--right"
            title="Next (arrow right)"
          ></button>

          <div className="pswp__caption">
            <div className="pswp__caption__center"></div>
          </div>

          <div className="pswp__minimap"></div>
        </div>
      </div>
    </div>
  );
});

export default PhotoSwipeLayout;
