import React from "react";
import preval from "preval.macro";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.dark,
    textAlign: "center",
  },
  copyright: {
    [theme.breakpoints.up("md")]: {
      "&::after": {
        content: "' \u2219 '",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      whiteSpace: "nowrap",
    },
  },
  update: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
      whiteSpace: "nowrap",
    },
  },
}));

function Footer(props) {
  const classes = useStyles(props);
  const buildTime = preval`
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const format = new Intl.DateTimeFormat('en-US', options)
    module.exports = format.format(new Date());
  `;
  return (
    <div className={classes.root}>
      <span className={classes.copyright}>Copyright 2020 Eric Seckler</span>
      <span className={classes.update}>Last updated {buildTime}</span>
    </div>
  );
}

export default Footer;
