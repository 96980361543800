import codegen from "codegen.macro";

const config = {
  groups: [
    // landscape
    {
      titleImage: require("./assets/photos/thumb/DSC_7368.jpg"),
      title: "Landscapes",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_7368.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6184.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_3434-Pano.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6498.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6082.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_3146.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_5984.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_1330-Pano.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2715-Pano.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_6232-HDR.jpg"),
      title: "Sunrise/set",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_6232-HDR.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6515.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2428-HDR.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6672_HDR.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_9155-HDR.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_6729_HDR-Edit.jpg"),
      titlePosition: "10% 0%",
      title: "HDR",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_6729_HDR-Edit.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_5594_HDR.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2689-HDR.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0310_HDR.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2063_HDR.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_1035.jpg"),
      title: "Lava",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_1035.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_9548.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0017.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0910.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_8344.jpg"),
      ],
    },

    // Plants
    {
      titleImage: require("./assets/photos/thumb/DSC_5725.jpg"),
      titlePosition: "70% 0%",
      title: "Trees",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_5725.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_5739.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_7906.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_5992.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_7025.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0392.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_5794.jpg"),
      titlePosition: "0% 20%",
      title: "Waterfalls",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_5794.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_4280-Pano.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_1239.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_4259.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6731.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_8728.jpg"),
      title: "Flowers",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_8728.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0232.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0703.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0689.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0472.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_8441.jpg"),
      titlePosition: "70% 0%",
      title: "Plants",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_8441.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_5797.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0703-2.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_4681.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6607.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0170.jpg"),
      ],
    },

    // wildlife
    {
      titleImage: require("./assets/photos/thumb/DSC_2897.jpg"),
      title: "Wildlife",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_2897.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_7394.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2321.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_1355.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_3554.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_3311.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_9719.jpg"),
      titlePosition: "30% 0%",
      title: "Primates",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_9719.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_9597.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0552.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_7021.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_0050.jpg"),
      title: "Bees",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_0050.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0083.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0092.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0323.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0011-2.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_2325.jpg"),
      title: "Insects",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_2325.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2269.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2288.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0124.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2736.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0272.jpg"),
      ],
    },

    // marine life
    {
      titleImage: require("./assets/photos/thumb/DSC_0054-Edit.jpg"),
      title: "Marine life",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_0054-Edit.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_7111.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2922.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2462.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2494.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_7162.jpg"),
      title: "Fish",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_7162.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_7417-Edit.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6769.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6555.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6690.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_7272.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_6305.jpg"),
      titlePosition: "0% 10%",
      title: "Nudibranches",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_6305.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6297.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_7061.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6694.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6509.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_7364.jpg"),
      title: "Crustaceans",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_7364.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_7360.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6686.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6303.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6903.jpg"),
      ],
    },

    // objects / people
    {
      titleImage: require("./assets/photos/thumb/DSC_0471.jpg"),
      title: "Objects",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_0471.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2386.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6818.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0509.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_8071.jpg"),
      title: "Cities",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_8071.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_3195.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6871-Edit.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6967.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_3038.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_6845-HDR.jpg"),
      title: "Sights",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_6845-HDR.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2063_HDR.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_2174_HDR.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_1501-HDR.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_8468-HDR.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_8451.jpg"),
      titlePosition: "80% 0%",
      title: "People",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_8451.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0177.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0076.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0220.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_7757.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_1368.jpg"),
      ],
    },

    // macro
    {
      titleImage: require("./assets/photos/thumb/DSC_0260.jpg"),
      titlePosition: "80% 0%",
      title: "Macro",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_0260.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_4149.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0373.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_5954.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6953-2.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6421.jpg"),
      ],
    },
    {
      titleImage: require("./assets/photos/thumb/DSC_6835.jpg"),
      titlePosition: "50% 60%",
      title: "Patterns",
      items: [
        codegen.require("photosConfigItem.gen.js", "DSC_6835.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_0926.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_4772.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_7085.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_6832.jpg"),
        codegen.require("photosConfigItem.gen.js", "DSC_3646.jpg"),
      ],
    },
  ],
};

export default config;
