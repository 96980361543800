import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  contact: {
    position: "relative",
    paddingBottom: 8,
    minHeight: 32,
    textAlign: "left",
    display: "block",
  },
  icon: {
    position: "absolute",
    top: 0,
    left: 0,
    color: theme.palette.primary.main,
  },
  body: {
    paddingTop: 2,
    paddingLeft: 32,
  },
}));

function Contact(props) {
  const classes = useStyles(props);
  const RootComponent = props.href ? "a" : "div";
  return (
    <RootComponent className={classes.contact} href={props.href}>
      <div className={classes.icon}>{props.icon}</div>
      <div className={classes.body}>{props.body}</div>
    </RootComponent>
  );
}

export default Contact;
