import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "& .date": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.secondary.light
          : theme.palette.secondary.dark,
      fontSize: "0.75rem",
    },
    "& .title": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.primary[400]
          : theme.palette.primary.dark,
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    "& .location": {
      fontWeight: 500,
    },
    "& .extra": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.secondary[400]
          : "black",
    },
  },
}));

function TimelineEntry(props) {
  const classes = useStyles(props);
  return (
    <Grid
      container
      spacing={0}
      className={classes.root}
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12} className="date">
        {props.date}
      </Grid>
      <Grid item xs={12} className="title">
        {props.title}
      </Grid>
      <Grid item xs={12} className="location">
        {props.location}
      </Grid>
      {React.Children.map(props.children, (child, index) => (
        <Grid item xs={12} key={index} className="extra">
          {child}
        </Grid>
      ))}
    </Grid>
  );
}

export default TimelineEntry;
