import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import { isChromium } from "./helpers.js";
import Content from "./components/Content.js";
import Footer from "./components/Footer.js";
import Header from "./components/Header.js";
import Parallax from "./components/Parallax.js";
import PhotoSwipeLayout from "./components/PhotoSwipeLayout.js";

const useStyles = makeStyles((theme) => ({
  "@global": {
    a: {
      textDecoration: "none",
      color: "inherit",
      transition: theme.transitions.create("color", { duration: 150 }),
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    "html,body,#root": {
      overflow: "hidden",
      height: "100%",
    },
    "*": {
      transformStyle: "preserve-3d",
    },
  },
  root: {
    overflow: "hidden",
    height: "100%",
  },
  parallaxRoot: {
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    perspective: isChromium ? 1 : 0,
    perspectiveOrigin: "0 0",
    backgroundColor:
      theme.palette.type === "dark" ? "#060606" : "white",
    overscrollBehavior: "contain",
  },
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1200,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  containerBg: {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "90vh",
    "@media (min-height: 1212px)": {
      height: 1091,
    },
    backgroundColor: "black",
    zIndex: -2,
  },
  parallaxBg: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1200,
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    height: "80vh",
    "@media (min-height: 1212px)": {
      height: 970,
    },
    "& img": {
      width: "100%",
      height: "100%",
      maxWidth: 1168,
      objectFit: "contain",
      objectPosition: "bottom right",
    },
  },
  parallaxChild: {
    width: "90%",
    height: 200,
    maxWidth: 610,
    position: "absolute",
    top: "17%",
    left: "5%",
    "@media (max-width: 725px)": {
      top: "6%",
    },
  },
  header: {
    height: "80vh",
    "@media (min-height: 1212px)": {
      height: 970,
    },
    position: "relative",
  },
  content: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 16,
    position: "relative",
    overflow: "hidden",
  },
}));

function App(props) {
  const classes = useStyles(props);
  const photoSwipeLayout = useRef();

  const chromium = isChromium();
  const vulcanos = {
    webp: [
      require("./assets/vulcano_0k.webp"),
      require("./assets/vulcano_1k.webp"),
      require("./assets/vulcano_2k.webp"),
      require("./assets/vulcano.webp"),
    ],
    png: [
      require("./assets/vulcano_0k.png"),
      require("./assets/vulcano_1k.png"),
      require("./assets/vulcano_2k.png"),
      require("./assets/vulcano.png"),
    ],
  };
  const vulcanoSizes = [584, 1168, 2336, 2808];
  let srcSet = {
    webp: "",
    png: "",
  };
  for (let ext of ["webp", "png"]) {
    vulcanoSizes.forEach((size, index) => {
      if (srcSet[ext].length) srcSet[ext] += ",";
      // For Chromium, the parallax applies a scale(2) transform that is later
      // scaled back by the perspective to 1x. Adjusted image sizes for this.
      if (chromium) size *= 2;
      srcSet[ext] += vulcanos[ext][index] + " " + size + "w";
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.parallaxRoot} id="parallax-root">
        <div className={classes.container}>
          <div className={classes.header}>
            <Parallax depth={3} className={classes.parallaxChild}>
              <Header />
            </Parallax>
          </div>
          <Paper className={classes.content} elevation={4}>
            <Content photoSwipeLayout={photoSwipeLayout} />
          </Paper>
          <Footer />
        </div>
        <Parallax depth={1} className={classes.containerBg} />
        <Parallax depth={1} className={classes.parallaxBg}>
          <picture>
            <source type="image/webp" srcSet={srcSet["webp"]} />
            <source srcSet={srcSet["png"]} />
            <img src={require("./assets/vulcano_1k.png")} alt="Vulcano" />
          </picture>
        </Parallax>
      </div>
      <PhotoSwipeLayout ref={photoSwipeLayout} />
    </div>
  );
}

App.defaultProps = { image: require("./assets/vulcano.png") };

export default App;
