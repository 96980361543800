import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const itemRight = (theme) => ({
  marginLeft: "50%",
  textAlign: "left",
  paddingLeft: 24,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 16,
    marginBottom: 8,
  },
  "& .bullet": {
    left: -8,
  },
  "& ul": {
    padding: 0,
    paddingLeft: 20,
  },
  [theme.breakpoints.up("xl")]: {
    "& .date": {
      position: "absolute",
      top: 5,
      right: "calc(100% + 48px)",
      whiteSpace: "nowrap",
    },
  },
});

const itemLeft = (theme) => ({
  [theme.breakpoints.up("xl")]: {
    marginRight: "50%",
    textAlign: "right",
    paddingRight: 24,
    "& .bullet": {
      right: -8,
    },
    "& ul": {
      direction: "rtl",
      padding: 0,
      paddingRight: 20,
    },
    "& .date": {
      position: "absolute",
      top: 5,
      left: "calc(100% + 48px)",
      whiteSpace: "nowrap",
    },
  },
  [theme.breakpoints.down("lg")]: itemRight(theme),
});


const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "& .line": {
      position: "absolute",
      top: 0,
      left: "calc(50% - 1px)",
      width: 2,
      height: "100%",
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.secondary.light
          : theme.palette.secondary.dark,
      [theme.breakpoints.down("lg")]: {
        left: 15,
      },
    },
    "& .paddingTop": {
      height: 16,
    },
    "& .paddingBottom": {
      height: 16,
      [theme.breakpoints.down("lg")]: {
        height: 8,
      },
    },
    "& .item": {
      position: "relative",
      "& .bullet": {
        position: "absolute",
        top: 29,
        width: 16,
        height: 16,
        borderRadius: 8,
        backgroundColor:
          theme.palette.type === "dark"
            ? theme.palette.primary[400]
            : theme.palette.primary.main,
        [theme.breakpoints.up("xl")]: {
          top: 13,
        },
      },
      "& ul,p": {
        margin: 0,
        marginTop: 8,
      },
    },
    "& .itemRight": itemRight(theme),
    "& .itemLeft": itemLeft(theme),
  },
}));

function Timeline(props) {
  const classes = useStyles(props);

  let gridClasses = classes.root;
  if (props.className) gridClasses += " " + props.className;

  return (
    <Grid
      container
      spacing={2}
      className={gridClasses}
      justify="flex-start"
      alignItems="flex-start"
    >
      <div className="line"></div>
      <div className="paddingTop"></div>
      {React.Children.map(props.children, (child, index) => (
        <Grid
          item
          xs={12}
          key={index}
          className={"item " + (index % 2 === 0 ? "itemRight" : "itemLeft")}
        >
          <div className="bullet"></div>
          {child}
        </Grid>
      ))}
      <div className="paddingBottom"></div>
    </Grid>
  );
}

export default Timeline;
