import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import DialerSipIcon from "@material-ui/icons/DialerSip";
import MailIcon from "@material-ui/icons/Mail";
import SmartphoneIcon from "@material-ui/icons/Smartphone";

import { ReactComponent as FacebookLogo } from "../assets/facebook.svg";
import { ReactComponent as GithubLogo } from "../assets/github.svg";
import { ReactComponent as LinkedInLogo } from "../assets/linkedin.svg";
import { ReactComponent as TwitterLogo } from "../assets/twitter.svg";
import Contact from "./Contact.js";
import Photos from "./Photos.js";
import Project from "./Project.js";
import Timeline from "./Timeline.js";
import TimelineEntry from "./TimelineEntry.js";

import photosConfig from "../photosConfig.js";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.secondary[900] : "white",
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 0,
    marginBottom: 0,
    "& h1": {
      position: "relative",
      textTransform: "uppercase",
      fontSize: 24,
      marginTop: 16,
      marginBottom: 16,
    },
  },
  experience: {
    textAlign: "center",
  },
  education: {
    textAlign: "center",
  },
  timeline: {
    [theme.breakpoints.down("lg")]: {
      maxWidth: 262,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  projects: {
    margin: "16px -8px -8px -8px",
    flexBasis: "auto",
    maxWidth: "none",
    width: "calc(100% + 16px)",
    textAlign: "center",
    backgroundColor: theme.palette.secondary[800],
    color: theme.palette.secondary.contrastText,
    "& h1": {
      marginTop: 8,
    },
  },
  projectList: {
    padding: "0px 8px 8px 8px",
  },
  project: {},
  photos: {
    margin: "0px -8px 0 -8px",
    flexBasis: "auto",
    maxWidth: "none",
    width: "calc(100% + 16px)",
    textAlign: "center",
    backgroundColor: theme.palette.secondary[800],
    color: theme.palette.secondary.contrastText,
    "& h1": {
      marginTop: 8,
    },
  },
  about: {
    textAlign: "center",
    "& ul": {
      margin: "0 auto",
      maxWidth: 335,
      textAlign: "left",
      listStyle: "none",
      padding: "0 15px",
      "& li": {
        background:
          'url("data:image/svg+xml;utf8,' +
          "<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' " +
          "viewBox='-1 -1 2 2'><circle r='1.0' fill='" +
          theme.palette.primary.main.replace("#", "%23") +
          "' /></svg>\") no-repeat 0px 6px",
        paddingLeft: 25,
        paddingBottom: 8,
      },
      "& span": {
        display: "inline-block",
      },
    },
  },
  contact: {
    textAlign: "center",
  },
  contactInline: {
    display: "inline-block",
  },
  contactSocial: {
    padding: "8px 0",
  },
  linkedin: {
    padding: 8,
    borderRadius: 16,
    backgroundColor: "#2867b2",
    "& svg": {
      width: 16,
      height: 16,
      margin: "-0.5px -0.5px 0.5px 0.5px",
      transition: theme.transitions.create(["background-color", "fill"], {
        duration: 150,
      }),
      fill: "white",
    },
    "&:hover": {
      backgroundColor: "#245ca0",
    },
  },
  facebook: {
    padding: 0,
    backgroundColor: "#fff",
    "& svg": {
      width: 32,
      height: 32,
      transition: theme.transitions.create("fill", { duration: 150 }),
      fill: "#1877f2",
    },
    "&:hover": {
      backgroundColor: "#fff",
      fill: "#156bd9",
    },
    "&:hover svg": {
      fill: "#156bd9",
    },
  },
  twitter: {
    padding: 6,
    borderRadius: 18,
    backgroundColor: "#1da1f2",
    "& svg": {
      width: 20,
      height: 20,
      fill: "#fff",
    },
    "&:hover": {
      backgroundColor: "#1a90d9",
    },
  },
  github: {
    padding: 0,
    "& svg": {
      width: 32,
      height: 32,
      transition: theme.transitions.create("fill", { duration: 150 }),
      fill: theme.palette.type === "dark" ? "#fff" : "#000",
    },
    "&:hover svg": {
      fill: theme.palette.type === "dark" ? "#dddddd" :  "#191919",
    },
  },
}));

function Content(props) {
  const classes = useStyles(props);
  return (
    <Grid
      container
      spacing={2}
      className={classes.root}
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12} md={6} className={classes.experience}>
        <h1>Experience</h1>
        <div className={classes.timeline}>
          <Timeline>
            <TimelineEntry
              date="2016 &ndash;"
              title="Senior Software Engineer"
              location="Google &bull; London"
            >
              <ul>
                <li>Chrome/Android Performance</li>
                <li>Headless Chrome</li>
              </ul>
            </TimelineEntry>
            <TimelineEntry
              date="2013 &amp; 2015"
              title="Software Engineering Intern"
              location="Google &bull; Bay Area / Zurich"
            >
              <ul>
                <li>Server frameworks</li>
                <li>Smart Ads productivity</li>
              </ul>
            </TimelineEntry>
            <TimelineEntry
              date="2007 &ndash; 2015"
              title="Freelance developer"
              location="Berlin"
            >
              <ul>
                <li>Web design, native apps</li>
                <li>RFID-based timekeeping</li>
              </ul>
            </TimelineEntry>
          </Timeline>
        </div>
      </Grid>
      <Grid item xs={12} md={6} className={classes.education}>
        <h1>Education</h1>
        <div className={classes.timeline}>
          <Timeline>
            <TimelineEntry
              date="2015"
              title="Imperial College London"
              location="MRes Advanced Computing"
            >
              <p>Diffingo: message serialization</p>
            </TimelineEntry>
            <TimelineEntry
              date="2013"
              title="University of California"
              location="Computing (study abroad) &bull; Irvine"
            >
              <p>Byte-code interpreter for Ruby</p>
            </TimelineEntry>
            <TimelineEntry
              date="2009 &ndash; 2014"
              title="Hasso Plattner Institute"
              location={
                <span>
                  University of Potsdam
                  <br />
                  MSc + BSc IT Systems Engineering
                </span>
              }
            >
              <ul>
                <li>Archipelago: object spaces</li>
                <li>
                  Object-oriented programming for columnar in-memory databases
                </li>
              </ul>
            </TimelineEntry>
          </Timeline>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.projects}>
        <h1>Projects</h1>
        <Grid
          container
          spacing={2}
          className={classes.projectList}
          justify="center"
          alignItems="stretch"
        >
          <Grid item xs={12} sm={6} md={4} lg={2} className={classes.project}>
            <Project
              title="Headless Chrome"
              body="Modern web browser for automation"
              href="https://source.chromium.org/chromium/chromium/src/+/master:headless/README.md"
              image={require("../assets/chrome.svg")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} className={classes.project}>
            <Project
              title="Perfetto"
              body="System profiling &amp; app tracing"
              href="https://perfetto.dev"
              image={require("../assets/perfetto.png")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} className={classes.project}>
            <Project
              title="Timingbase"
              body="RFID-based sport timekeeping"
              href="https://www.davengo.com/cms/HOME/Timing"
              image={require("../assets/tblogo.png")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} className={classes.project}>
            <Project
              title="Archipelago"
              body="Replicated object spaces"
              href="https://github.com/betasheet/archipelago"
              image={require("../assets/archipelago.png")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} className={classes.project}>
            <Project
              title="Diffingo"
              body="App-specific message serialization"
              href="https://github.com/betasheet/diffingo"
              image={require("../assets/diffingo.png")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} className={classes.project}>
            <Project
              title="Quadro"
              body="Four rotors in the sky!"
              image={require("../assets/quadro.jpg")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} className={classes.about}>
        <h1>About Me</h1>
        <ul>
          <li>Born in Bavaria, grew up in a small town near Berlin.</li>
          <li>
            Met my amazing wife during a short stint in California and ended up
            in Cambridge, UK.
          </li>
          <li>
            Enthusiastic about building things, be it software, hardware, or
            Lego!
          </li>
          <li>
            Love to travel, dive, and climb &mdash; and take photos all the
            while :)
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} md={6} className={classes.contact}>
        <h1>Contact</h1>
        <div className={classes.contactInline}>
          <Contact
            href="&#109;&#x61;&#x69;&#108;&#x74;&#x6f;&#58;&#x65;&#x72;&#105;&#x63;&#x40;&#115;&#x65;&#x63;&#107;&#x6c;&#x65;&#114;&#x2e;&#x6f;&#114;&#x67;"
            icon={<MailIcon />}
            body="&#x65;&#x72;&#105;&#x63;&#x40;&#115;&#x65;&#x63;&#107;&#x6c;&#x65;&#114;&#x2e;&#x6f;&#114;&#x67;"
          />
          <Contact
            icon={<SmartphoneIcon />}
            href="tel:+447478310681"
            body="+44 7478 310681"
          />
          <Contact
            icon={<DialerSipIcon />}
            href="tel:+19492160523"
            body="+1 949-216-0523"
          />
        </div>
        <Grid
          container
          spacing={1}
          className={classes.contactSocial}
          justify="center"
          alignItems="flex-start"
        >
          <Grid item>
            <IconButton
              aria-label="LinkedIn"
              className={classes.linkedin}
              href="https://www.linkedin.com/in/ericseckler"
              target="_blank"
            >
              <LinkedInLogo />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Facebook"
              className={classes.facebook}
              href="https://www.facebook.com/betasheet"
              target="_blank"
            >
              <FacebookLogo />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Twitter"
              className={classes.twitter}
              href="https://twitter.com/betasheet23"
              target="_blank"
            >
              <TwitterLogo />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Github"
              className={classes.github}
              href="https://github.com/betasheet"
              target="_blank"
            >
              <GithubLogo />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.photos}>
        <h1>Photography</h1>
        <Photos photoSwipeLayout={props.photoSwipeLayout} {...photosConfig} />
      </Grid>
    </Grid>
  );
}

export default Content;
