import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import App from "./App";
import { getTheme } from "./theme";

function AppTheme(props) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const muiTheme = React.useMemo(
    () => createMuiTheme(getTheme(prefersDarkMode ? 'dark' : 'light')),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
}

export default AppTheme;
