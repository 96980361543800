import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    height: 200,
    borderRadius: 4,
    overflow: "hidden",
    transition: theme.transitions.create("box-shadow", { duration: 150 }),
    "& img": {
      width: "100%",
      height: 200,
      objectFit: "cover",
      objectPosition: props => props.group.titlePosition,
      transition: theme.transitions.create("filter", { duration: 150 }),
    },
    "& .title": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "#fff",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      padding: 16,
    },
    "& h2": {
      transition: theme.transitions.create("transform", { duration: 150 }),
      fontWeight: 400,
    },
    "&:hover, &.focused": {
      zIndex: 1,
      boxShadow: "0 0 0 1px #fff",
      "& img": {
        filter: "brightness(1.3)",
      },
      "& .title h2": {
        transform: "scale(1.1)",
      },
    },
  },
  paper: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
}));

function PhotoGroup(props) {
  const classes = useStyles(props);

  const { group, groupNumber, open } = props;
  const openGroup = useCallback(() => {
    open(groupNumber);
  }, [open, groupNumber]);

  return (
    <ButtonBase
      focusRipple
      className={classes.button}
      focusVisibleClassName="focused"
      onClick={openGroup}
    >
      <Paper className={classes.paper} elevation={1}>
        <img src={group.titleImage} alt={group.title} />
        <div className="title">
          <Typography variant="h6" component="h2">
            {group.title}
          </Typography>
        </div>
      </Paper>
    </ButtonBase>
  );
}

export default PhotoGroup;
