import React from "react";
import ReactDOM from "react-dom";

import "typeface-raleway";
import "typeface-roboto";

import AppTheme from "./AppTheme";

ReactDOM.render(
  <AppTheme />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//import * as serviceWorker from './serviceWorker';
//serviceWorker.unregister();
