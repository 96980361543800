import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { isChromium } from "../helpers.js";

function makeTransform(props) {
  if (!isChromium())
    return undefined;
  const dx = props.left * props.depth + (props.width / 2) * props.depth;
  const dy = props.top * props.depth + (props.height / 2) * props.depth;
  const dz = -props.depth;
  const translate = "translate3d(" + dx + "px, " + dy + "px, " + dz + "px)";
  const scale = "scale(" + (1 + props.depth) + ")";
  return translate + " " + scale;
}

function getOffsetsFromRoot(element) {
  const rootElement = document.getElementById("parallax-root");
  let top = 0,
    left = 0;
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element && element !== rootElement);
  return { top, left };
}

const useStyles = makeStyles({
  parallax: {
    transform: (props) => makeTransform(props),
  },
});

export default function Parallax(props) {
  const [dimensions, setDimensions] = React.useState({
    top: 0,
    left: 0,
    height: 0,
    width: 0,
  });

  const style_props = { ...props, ...dimensions };
  const classes = useStyles(style_props);
  const div = React.useRef(null);

  React.useEffect(() => {
    const handleResize = () => {
      const offset = getOffsetsFromRoot(div.current);
      setDimensions({
        top: offset.top,
        left: offset.left,
        height: div.current.offsetHeight,
        width: div.current.offsetWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let divClasses = classes.parallax;
  if (props.className) divClasses += " " + props.className;

  return (
    <div className={divClasses} ref={div}>
      {props.children}
    </div>
  );
}
